import inventoryAdjustment from './inventory-management/inventory-adjustment';
import cycleCount from './inventory-management/cycle-count';
import fullStockCount from './inventory-management/full-stock-count';
import surplusRegistration from './inventory-management/surplus-registration';
import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import { lazy } from 'react';
const inventoryManagement = {
    path: '/inventorymanage',
    name: 'inventoryManagement',
    meta: {
        title: 'Inventory Management',
        icon: 'Inventory Management',
    },
    redirect: '/inventorymanage/racktransfer',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: 'racktransfer',
            name: 'inventoryManagement.racktransfer',
            meta: {
                title: 'Rack Transfer',
            },
            redirect: '/inventorymanage/racktransfer/order',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'order',
                    name: 'inventoryManagement.racktransfer.order',
                    meta: {
                        title: 'Rack Transfer Order',
                        filterSave: true,
                    },
                    component: () => import('@/views/inventory-management/rack-transfer/order/index.vue'),
                },
                {
                    path: 'task',
                    name: 'inventoryManagement.racktransfer.task',
                    meta: {
                        title: 'Putaway Task',
                    },
                    redirect: '/inventorymanage/racktransfer/putawaytask',
                    component: () => import('../../views/layout/subPage.vue'),
                },
                {
                    path: 'putawaytask',
                    name: 'inventoryManagement.racktransfer.putawayTask',
                    meta: {
                        title: 'Putaway Task',
                        filterSave: true,
                        menuHide: true,
                    },
                    component: () => import('../../views/task-center/inbound-task/putaway-task/rack-transfer/putaway-task.vue'),
                },
                {
                    path: 'putawaytask/edit',
                    name: 'inventoryManagement.racktransfer.putawayTask.edit',
                    meta: {
                        title: 'Putaway Task Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'inventoryManagement',
                                title: 'Inventory Management',
                            },
                            {
                                routeName: 'inventoryManagement.racktransfer',
                                title: 'Rack Transfer',
                            },
                            {
                                routeName: 'inventoryManagement.racktransfer.putawayTask',
                                title: 'Putaway Task',
                            },
                        ],
                    },
                    component: () => import('../../views/task-center/inbound-task/putaway-task/rack-transfer/putaway-task-detail.vue'),
                },
                {
                    path: 'putawaytask/detail',
                    name: 'inventoryManagement.racktransfer.putawayTask.detail',
                    meta: {
                        title: 'Putaway Task Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'inventoryManagement',
                                title: 'Inventory Management',
                            },
                            {
                                routeName: 'inventoryManagement.racktransfer',
                                title: 'Rack Transfer',
                            },
                            {
                                routeName: 'inventoryManagement.racktransfer.putawayTask',
                                title: 'Putaway Task',
                            },
                        ],
                    },
                    component: () => import('../../views/task-center/inbound-task/putaway-task/rack-transfer/putaway-task-detail.vue'),
                },
                {
                    path: 'picking',
                    name: 'inventoryManagement.racktransfer.picking',
                    meta: {
                        title: 'Rack Transfer Picking',
                    },
                    component: () => import('@/views/inventory-management/rack-transfer/picking/index.vue'),
                },
                {
                    path: 'devicepicking',
                    name: 'inventoryManagement.racktransfer.devicePicking',
                    meta: {
                        title: 'Device Picking',
                    },
                    component: () => import('@/views/inventory-management/rack-transfer/device-picking/index.vue'),
                },
                {
                    path: 'standardputaway',
                    name: 'inventoryManagement.racktransfer.standardPutaway',
                    meta: {
                        title: 'Standard Putaway',
                    },
                    component: () => import('../../views/inventory-management/rack-transfer/standard-putaway/standard-putaway.vue'),
                },
                {
                    path: 'massputaway',
                    name: 'inventoryManagement.racktransfer.massPutaway',
                    meta: {
                        title: 'Mass Putaway',
                    },
                    component: () => import('../../views/inventory-management/rack-transfer/mass-putaway/mass-putaway.vue'),
                },
                {
                    path: 'putback',
                    name: 'inventoryManagement.racktransfer.putback',
                    meta: {
                        title: 'Putback',
                    },
                    component: () => import('../../views/inventory-management/rack-transfer/putback/putback.vue'),
                },
            ],
        },
        {
            path: 'racktransfer/create',
            name: 'rackTransfer.create',
            meta: {
                title: 'Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.racktransfer',
                        title: 'Rack Transfer',
                    },
                    {
                        routeName: 'inventoryManagement.racktransfer.order',
                        title: 'Rack Transfer Order',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/rack-transfer/order/create/index.vue'),
        },
        {
            path: 'racktransfer/transfer',
            name: 'rackTransfer.transfer',
            meta: {
                title: 'Transfer',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.racktransfer',
                        title: 'Rack Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/rack-transfer/order/transfer/index.vue'),
        },
        {
            path: 'racktransfer/detail',
            name: 'rackTransfer.detail',
            meta: {
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.racktransfer',
                        title: 'Rack Transfer',
                    },
                    {
                        routeName: 'inventoryManagement.racktransfer.order',
                        title: 'Rack Transfer Order',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/rack-transfer/order/detail/index.vue'),
        },
        {
            path: 'replenishmentmanagement',
            name: 'inventoryManagement.replenishmentManagement',
            meta: {
                title: 'Replenishment Management',
            },
            redirect: '/inventorymanage/replenishmentmanagement/plan',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'plan',
                    name: 'inventoryManagement.replenishmentManagement.plan',
                    meta: {
                        title: 'Replenishment Plan',
                    },
                    component: () => import('@/views/inventory-management/replenishment-plan/index.vue'),
                },
                {
                    path: 'plan/create',
                    name: 'inventoryManagement.replenishmentManagement.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'inventoryManagement',
                                title: 'Inventory Management',
                            },
                            {
                                routeName: 'inventoryManagement.replenishmentManagement',
                                title: 'Replenishment Management',
                            },
                            {
                                routeName: 'inventoryManagement.replenishmentManagement.plan',
                                title: 'Replenishment Plan',
                            },
                        ],
                    },
                    component: () => import('@/views/inventory-management/replenishment-plan/create.vue'),
                },
                {
                    path: 'wavelog',
                    name: 'inventoryManagement.replenishmentManagement.wavelog',
                    meta: {
                        title: 'Replenishment Wave Log',
                        filterSave: true,
                    },
                    component: () => import('../../views/inventory-management/wave-log/wave-log.vue'),
                },
                {
                    path: 'wavelog/:wavesn',
                    name: 'inventoryManagement.wavelog.detail',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'inventoryManagement',
                                title: 'Inventory Management',
                            },
                            {
                                routeName: 'inventoryManagement.replenishmentManagement.wavelog',
                                title: 'Wave Log',
                            },
                        ],
                    },
                    component: () => import('../../views/inventory-management/wave-log/log-detail.vue'),
                },
                {
                    path: 'strategy',
                    name: 'inventoryManagement.replenishmentManagement.strategy',
                    meta: {
                        title: 'Replenishment Strategy',
                    },
                    redirect: '/inventorymanage/replenishmentmanagement/strategy/forecastsales',
                    component: () => import('@/views/inventory-management/inventory-strategy'),
                    children: [
                        {
                            path: 'forecastsales',
                            name: 'PC.InventoryManagement.Replenishment.Strategy.ForecastSales.View',
                            meta: {
                                title: 'Forecast Sales',
                                menuHide: true,
                            },
                            component: () => import('@/views/inventory-management/inventory-strategy/forecast-sales'),
                        },
                        {
                            path: 'safetyandtarget',
                            name: 'PC.InventoryManagement.Replenishment.Strategy.SafetyAndTargetStock.View',
                            meta: {
                                title: 'Safety and Target Stock',
                                menuHide: true,
                            },
                            component: () => import('@/views/inventory-management/inventory-strategy/safety-and-target'),
                        },
                        {
                            path: 'dailysalesreport',
                            name: 'PC.InventoryManagement.Replenishment.Strategy.DailySalesReport.View',
                            meta: {
                                title: 'Daily Sales Report',
                                menuHide: true,
                            },
                            component: () => import('@/views/inventory-management/inventory-strategy/daily-sales-report'),
                        },
                    ],
                },
                {
                    path: 'return',
                    name: 'inventoryManagement.replenishmentManagement.return',
                    meta: {
                        title: 'Return',
                    },
                    redirect: '/inventorymanage/replenishmentmanagement/return/demandPool',
                    component: () => import('../../views/layout/subPage.vue'),
                },
                {
                    path: 'return/:tab',
                    name: 'inventoryManagement.replenishmentManagement.return',
                    meta: {
                        title: 'Return',
                    },
                    component: () => import('../../views/layout/subPage.vue'),
                },
            ],
        },
        {
            path: 'ownerTransfer',
            name: 'inventoryManagement.ownerTransfer',
            meta: {
                title: 'Owner Transfer',
                filterSave: true,
            },
            component: () => import('@/views/inventory-management/owner-transfer/index.vue'),
        },
        {
            path: 'ownerTransfer/detail/:task_id',
            name: 'inventoryManagement.ownerTransfer.detail',
            meta: {
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.ownerTransfer',
                        title: 'Owner Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/owner-transfer/detail.vue'),
        },
        {
            path: 'batchTransfer',
            name: 'inventoryManagement.batchTransfer',
            meta: {
                title: 'Batch Transfer',
                filterSave: true,
            },
            component: () => import('@/views/inventory-management/batch-transfer/index.vue'),
        },
        {
            path: 'batchTransfer/create',
            name: 'batchTransfer.create',
            meta: {
                title: 'Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.batchTransfer',
                        title: 'Batch Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/batch-transfer/create.vue'),
        },
        {
            path: 'batchTransfer/edit',
            name: 'batchTransfer.edit',
            meta: {
                title: 'Edit Task',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.batchTransfer',
                        title: 'Batch Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/batch-transfer/batchTransferEdit.vue'),
        },
        {
            path: 'batchTransfer/view',
            name: 'batchTransfer.view',
            meta: {
                title: 'Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.batchTransfer',
                        title: 'Batch Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/batch-transfer/batchTransferView.vue'),
        },
        {
            path: 'batchTransfer/edit',
            name: 'batchTransfer.edit',
            meta: {
                title: 'Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.batchTransfer',
                        title: 'Batch Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/batch-transfer/batchTransferEdit.vue'),
        },
        {
            path: 'batchTransfer/view/skudetail',
            name: 'batchTransfer.view.skudetail',
            meta: {
                title: 'Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.batchTransfer',
                        title: 'Batch Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/batch-transfer/batchTransferSkuDetail.vue'),
        },
        {
            path: 'batchTransfer/edit/skudetail',
            name: 'batchTransfer.edit.skudetail',
            meta: {
                title: 'Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.batchTransfer',
                        title: 'Batch Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/batch-transfer/batchTransferSkuDetail.vue'),
        },
        {
            path: 'batchTransfer/edit/skuedit',
            name: 'batchTransfer.edit.skuedit',
            meta: {
                title: 'Edit SKU',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.batchTransfer',
                        title: 'Batch Transfer',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/batch-transfer/batchTransferSkuEdit.vue'),
        },
        {
            path: 'rearrangement',
            name: 'inventoryManagement.rearrangement',
            meta: {
                title: 'Re-arrangement Report',
                filterSave: true,
            },
            component: () => import('@/views/inventory-management/re-arrangement/list/index.vue'),
        },
        {
            path: 'rearrangement/:id',
            name: 'inventoryManagement.rearrangement.detail',
            meta: {
                title: 'Re-arrangement Report Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryManagement',
                        title: 'Inventory Management',
                    },
                    {
                        routeName: 'inventoryManagement.rearrangement',
                        title: 'Re-arrangement Report',
                    },
                ],
            },
            component: () => import('@/views/inventory-management/re-arrangement/view/index.vue'),
        },
        inventoryAdjustment,
        surplusRegistration,
        cycleCount,
        fullStockCount,
        {
            path: 'inspect',
            name: 'inventoryManagement.inspect',
            meta: {
                title: 'Inspect',
            },
            redirect: '/inventorymanage/inspect/task',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'task',
                    name: 'inventoryManagement.inspect.task',
                    meta: {
                        title: 'Inspect Task',
                    },
                    component: reactContainerFactory({
                        name: 'inventoryManagement.inspect.task',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/inspect/inspect-task/index')),
                    },
                },
                {
                    path: 'task-detail',
                    name: 'PC.InventoryManagement.Inspect.Task.Detail.View',
                    meta: {
                        title: 'Inspect Task Detail',
                        menuHide: true,
                    },
                    component: reactContainerFactory({
                        name: 'PC.InventoryManagement.Inspect.Task.Detail.View',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/inspect/inspect-task-detail/index')),
                    },
                },
                {
                    path: 'operation',
                    name: 'inventoryManagement.inspect.operation',
                    meta: {
                        title: 'Inspect Operation',
                    },
                    component: reactContainerFactory({
                        name: 'inventoryManagement.inspect.operation',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/inspect/inspect-operation/index')),
                    },
                },
            ],
        },
        {
            path: 'replenish-reverse',
            name: 'inventoryManagement.replenishReverse',
            meta: {
                title: 'Replenish/Reverse',
            },
            redirect: '/inventorymanage/replenish-reverse/demand-pool',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'demand-pool',
                    name: 'inventoryManagement.replenishReverse.demandPool',
                    meta: {
                        title: 'Demand Pool',
                    },
                    component: reactContainerFactory({
                        name: 'inventoryManagement.replenishReverse.demandPool',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/inventory-management/replenish-reverse')),
                    },
                    redirect: '/inventorymanage/replenish-reverse/demand-pool/overview',
                    children: [
                        {
                            path: 'overview',
                            name: 'inventoryManagement.replenishReverse.demandPool.overview',
                            meta: {
                                title: 'Overview',
                                menuHide: true,
                            },
                        },
                        {
                            path: 'urgent-replenishment',
                            name: 'PC.InventoryManagement.ReplenishReverse.DemandPool.UrgentReplenishment.View',
                            meta: {
                                title: 'Urgent Replenishment',
                                menuHide: true,
                            },
                            redirect: '/inventorymanage/replenish-reverse/demand-pool/urgent-replenishment/order',
                            children: [
                                {
                                    path: 'order',
                                    name: 'PC.InventoryManagement.ReplenishReverse.DemandPool.UrgentReplenishment.Order.View',
                                    meta: {
                                        title: 'Order',
                                        menuHide: true,
                                    },
                                },
                                {
                                    path: 'sku',
                                    name: 'PC.InventoryManagement.ReplenishReverse.DemandPool.UrgentReplenishment.SKU.View',
                                    meta: {
                                        title: 'SKU',
                                        menuHide: true,
                                    },
                                },
                            ],
                        },
                        {
                            path: 'daily-replenishment',
                            name: 'PC.InventoryManagement.ReplenishReverse.DemandPool.DailyReplenishment.View',
                            meta: {
                                title: 'Daily Replenishment',
                                menuHide: true,
                            },
                        },
                        {
                            path: 'reverse',
                            name: 'PC.InventoryManagement.ReplenishReverse.DemandPool.Reverse.View',
                            meta: {
                                title: 'Reverse',
                                menuHide: true,
                            },
                        },
                        {
                            path: 'demand-pool/calculation-failure',
                            name: 'inventoryManagement.replenishReverse.demandPool.calculationFailure',
                            meta: {
                                title: 'Calculation Failure',
                                menuHide: true,
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
export default inventoryManagement;
