import router from '@/router/index';
import { getCookie } from '@/utils/cookie';
import Vue from 'vue';
import { format } from '@/utils/date';
import store from '@/store/index';
async function showOldSystemDateDialog(searchData, initModule) {
    let module = '';
    if (initModule) {
        module = initModule;
    }
    else {
        if (['inbound', 'moveinbound', 'returninbound', 'reportcenter'].some((x) => router.history.current.path.includes(x))) {
            module = 'INBOUND';
        }
        else if (['salesoutbound', 'rtsoutbound', 'mtoutbound'].some((x) => router.history.current.path.includes(x))) {
            module = 'OUTBOUND';
        }
        else if (['inventorymanage', 'inventorymap'].some((x) => router.history.current.path.includes(x))) {
            module = 'INVENTORY';
        }
    }
    const warehouse = getCookie('setting_whs_v2') || '';
    if (!store.state.warehouseSwitchTime.warehouseSwitchTime[module]) {
        return;
    }
    const warehouseChangeTimestamp = store.state.warehouseSwitchTime.warehouseSwitchTime[module][warehouse] || 0;
    const searchDataValueList = Object.values(searchData);
    for (const item of searchDataValueList) {
        const regExp = /^1\d{9}$/;
        if (typeof item !== 'number' || String(item).length !== 10) {
            return;
        }
        if (regExp.test(item) && item < warehouseChangeTimestamp) {
            Vue.prototype.$msgbox({
                title: 'Notice',
                message: `Date before ${format(warehouseChangeTimestamp, 'day')} is in old system. Please proceed to old WMS to retrieve data.`,
                showCancelButton: false,
            });
        }
    }
}
export default showOldSystemDateDialog;
