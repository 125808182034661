import request from '@/utils/request';
export const createUser = async (params) => {
    const res = await request.post('/api/v2/apps/config/userlist/create_user', {
        ...params,
    });
    return res;
};
export const editUser = async (params) => {
    const res = await request.post('/api/v2/apps/config/userlist/edit_user', {
        ...params,
    });
    return res;
};
export const getUserList = async (params) => {
    const res = await request.get('/api/v2/apps/config/userlist/get_user_list', {
        params: {
            ...params,
        },
    });
    return res;
};
export const resetUserPassword = async (params) => {
    const res = await request.post('/api/v2/apps/config/userlist/reset_user_password', {
        ...params,
    });
    return res;
};
export const searchEmail = async (params) => {
    const res = await request.get('/api/v2/apps/config/userlist/search_email', {
        params: {
            ...params,
        },
    });
    return res;
};
export const uploadUserList = async (params) => {
    const res = await request.post('/api/v2/apps/conf/userlist/upload_user_list', {
        ...params,
    });
    return res;
};
export const getRoleConfiguration = async () => {
    const res = await request.get('/api/v2/apps/config/userlist/get_role_configuration', {});
    return res;
};
export const searchAccessStatusLog = async (params) => {
    const res = await request.get('/api/v2/apps/config/userlist/search_access_status_log', {
        params: {
            ...params,
        },
    });
    return res;
};
export const createBpoUser = async (params) => {
    /* PermissionKey: ALL */
    const res = await request.post('/api/v2/apps/config/userlist/create_bpo_user', {
        ...params,
    });
    return res;
};
export const searchUploadTask = async (params) => {
    /* PermissionKey: ALL */
    const res = await request.get('/api/v2/apps/config/userlist/search_upload_task', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getBpoRoleList = async (params) => {
    /* PermissionKey: ALL */
    const res = await request.get('/api/v2/apps/config/userlist/get_bpo_role_list', {
        params: {
            ...params,
        },
    });
    return res;
};
export const syncUserToTob = async (params) => {
    const res = await request.post(
    /* PermissionKey: PC.SystemConfig.UserList.Action.Edit */
    '/api/v2/apps/config/userlist/sync_user_to_tob', {
        ...params,
    });
    return res;
};
export const resetMyPwd = async (params) => {
    const res = await request.post(
    /* PermissionKey: ALL */
    '/api/v2/apps/system/user/reset_my_pwd', {
        ...params,
    });
    return res;
};
