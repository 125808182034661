import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import { lazy } from 'react';
const inbound = {
    path: '/inbound',
    name: 'inbound',
    meta: {
        title: 'Inbound',
        icon: 'Inbound',
    },
    redirect: '/inbound/order',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: '/inbound/orderasn',
            name: 'inbound.orderasn',
            meta: {
                title: 'Order',
            },
            redirect: '/inbound/order',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: '/inbound/order',
            name: 'PC.Inbound.Order.InboundASN.View',
            meta: {
                menuHide: true,
                title: 'ASN',
                filterSave: true,
            },
            component: () => import('../../views/inbound/order/index.vue'),
        },
        {
            path: '/inbound/order/detail/:id',
            name: 'inbound.order.detail',
            meta: {
                icon: '',
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Order.InboundASN.View',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/inbound/order/asn/detail.vue'),
        },
        {
            path: '/inbound/order/createasn',
            name: 'inbound.order.createasn',
            meta: {
                icon: '',
                title: 'Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Order.InboundASN.View',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/inbound/order/asn/create-asn.vue'),
        },
        {
            path: 'task',
            name: 'inbound.task',
            meta: {
                title: 'Task',
            },
            redirect: '/inbound/boxingtask',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'boxingtask',
            name: 'PC.Inbound.Task.BoxingTask.View',
            meta: {
                menuHide: true,
                title: 'Boxing Task',
                filterSave: true,
            },
            component: () => import('../../views/task-center/inbound-task/boxing-task/index.vue'),
        },
        {
            path: 'boxingtaskdetail',
            name: 'inbound.boxingTaskDetail',
            meta: {
                title: 'Boxing Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Task.BoxingTask.View',
                        title: 'Boxing Task',
                    },
                ],
            },
            component: () => import('../../views/task-center/inbound-task/boxing-task/boxing-detail.vue'),
        },
        {
            path: 'collectingtask',
            name: 'PC.Inbound.Task.NewSKUCollectingTask.View',
            meta: {
                menuHide: true,
                title: 'SKU Collection Task',
                filterSave: true,
            },
            component: () => import('../../views/task-center/inbound-task/collecting-task/index.vue'),
        },
        {
            path: 'qctask',
            name: 'PC.Inbound.Task.QCTask.View',
            meta: {
                menuHide: true,
                title: 'QC Task',
                filterSave: true,
            },
            component: () => import('../../views/task-center/inbound-task/qc-task/qc-task.vue'),
        },
        {
            path: 'qctaskdetail',
            name: 'inbound.qcTaskDetail',
            meta: {
                title: 'QC Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Task.QCTask.View',
                        title: 'Inbound Task',
                    },
                ],
            },
            component: () => import('../../views/task-center/inbound-task/qc-task/qc-task-detail.vue'),
        },
        {
            path: 'receivetask',
            name: 'PC.Inbound.Task.ReceiveTask.View',
            meta: {
                menuHide: true,
                title: 'Receiving Task',
                filterSave: true,
            },
            component: () => import('../../views/task-center/inbound-task/receive-task/receive-task.vue'),
        },
        {
            path: 'receivetaskdetail',
            name: 'inbound.receiveTaskDetail',
            meta: {
                title: 'Receiving Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Task.ReceiveTask.View',
                        title: 'Receiving Task',
                    },
                ],
            },
            component: () => import('../../views/task-center/inbound-task/receive-task/receive-task-detail.vue'),
        },
        {
            path: 'putawaytask',
            name: 'PC.Inbound.Task.PutawayTask.View',
            meta: {
                title: 'Putaway Task',
                menuHide: true,
                filterSave: true,
            },
            component: () => import('../../views/task-center/inbound-task/putaway-task/inbound/putaway-task.vue'),
        },
        {
            path: 'putawaytask/edit',
            name: 'inbound.putawayTask.edit',
            meta: {
                title: 'Putaway Task Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Task.PutawayTask.View',
                        title: 'Putaway Task',
                    },
                ],
            },
            component: () => import('../../views/task-center/inbound-task/putaway-task/inbound/putaway-task-detail.vue'),
        },
        {
            path: 'putawaytask/detail',
            name: 'inbound.putawayTask.detail',
            meta: {
                title: 'Putaway Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Task.PutawayTask.View',
                        title: 'Putaway Task',
                    },
                ],
            },
            component: () => import('../../views/task-center/inbound-task/putaway-task/inbound/putaway-task-detail.vue'),
        },
        {
            path: 'reprocesstask',
            name: 'PC.Inbound.Task.ReprocessTask.View',
            meta: {
                title: 'Reprocess Task',
                menuHide: true,
                filterSave: true,
            },
            component: () => import('../../views/task-center/inbound-task/reprocess-task/index.vue'),
        },
        {
            path: 'reprocesstask/detail/:id',
            name: 'inbound.reprocessTask.detail',
            meta: {
                title: 'Reprocess Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Task.ReprocessTask.View',
                        title: 'Reprocess Task',
                    },
                ],
            },
            component: () => import('../../views/task-center/inbound-task/reprocess-task/detail.vue'),
        },
        {
            path: 'ibtPickingtask',
            name: 'PC.Inbound.Task.IBTPickingTask.View',
            meta: {
                title: 'IBT Picking Task',
                menuHide: true,
                filterSave: true,
            },
            component: () => import('../../views/task-center/inbound-task/ibt-picking-task/ibt-picking-task.vue'),
        },
        {
            path: 'ibtPickingtask/detail',
            name: 'inbound.IBTPickingTask.detail',
            meta: {
                title: 'IBT Picking Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Task.IBTPickingTask.View',
                        title: 'IBT Picking Task',
                    },
                ],
            },
            component: () => import('../../views/task-center/inbound-task/ibt-picking-task/ibt-picking-task-detail.vue'),
        },
        {
            path: '/inbound/arriving',
            name: 'inbound.arriving',
            meta: {
                title: 'Arriving',
                icon: '',
            },
            component: () => import('../../views/inbound/arriving/index.vue'),
        },
        {
            path: 'counting',
            name: 'inbound.counting',
            meta: {
                title: 'Counting',
                icon: '',
            },
            component: () => import('../../views/inbound/counting/counting.vue'),
        },
        {
            path: 'boxing',
            name: 'inbound.boxing',
            meta: {
                title: 'Boxing',
                icon: '',
            },
            component: () => import('../../views/inbound/boxing/boxing.vue'),
        },
        {
            path: 'qc',
            name: 'inbound.qc',
            meta: {
                title: 'QC',
                icon: '',
            },
            component: () => import('../../views/inbound/qc/index.vue'),
        },
        {
            path: 'receiving',
            name: 'inbound.receiving',
            meta: {
                title: 'Receiving',
                icon: '',
            },
            component: () => import('../../views/inbound/receiving/receiving.vue'),
        },
        {
            path: 'receivingreportexception',
            name: 'inbound.receivingReportException',
            meta: {
                title: 'Report Exception',
                icon: '',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'inbound.receiving',
                        title: 'Receiving',
                    },
                ],
            },
            component: () => import('../../views/inbound/receiving/report-exception.vue'),
        },
        {
            path: 'standardputaway',
            name: 'inbound.standardPutaway',
            meta: {
                title: 'Standard Putaway',
                icon: '',
            },
            component: () => import('../../views/inbound/standard-putaway/standard-putaway.vue'),
        },
        {
            path: 'standardputaway/reportexception',
            name: 'inbound.standardPutawayReportException',
            meta: {
                title: 'Report Exception',
                icon: '',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'inbound.standardPutaway',
                        title: 'Standard Putaway',
                    },
                ],
            },
            component: () => import('../../views/inbound/standard-putaway/report-exception.vue'),
        },
        {
            path: '/inbound/asngroup',
            name: 'PC.Inbound.Order.ASNGroup.View',
            meta: {
                menuHide: true,
                title: 'ASN Group',
                icon: '',
                filterSave: true,
            },
            component: () => import('../../views/inbound/order/index.vue'),
        },
        {
            path: '/inbound/queuelist',
            name: 'PC.Inbound.Order.QueueList.View',
            meta: {
                menuHide: true,
                title: 'Queue List',
                icon: '',
            },
            component: () => import('../../views/inbound/order/index.vue'),
        },
        {
            path: '/inbound/asngroup/detail',
            name: 'inbound.asnGroup.detail',
            meta: {
                title: 'ASN Group Detail',
                menuHide: true,
                icon: '',
                navList: [
                    {
                        routeName: 'inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Order.ASNGroup.View',
                        title: 'ASN Group',
                    },
                ],
            },
            component: () => import('../../views/inbound/order/asn-group/detail.vue'),
        },
        {
            path: '/inbound/trackingid',
            name: 'PC.Inbound.Order.TrackingID.View',
            meta: {
                menuHide: true,
                title: 'Tracking ID',
                icon: '',
                filterSave: true,
            },
            component: () => import('../../views/inbound/order/index.vue'),
        },
        {
            path: '/inbound/trackingiddetail',
            name: 'inbound.trackingIdDetail',
            meta: {
                menuHide: true,
                title: 'Tracking ID Detail',
                icon: '',
                navList: [
                    {
                        routeName: 'inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Order.TrackingID.View',
                        title: 'Tracking ID',
                    },
                ],
            },
            redirect: '/inbound/trackingiddetailview',
            component: () => import('../../views/inbound/order/tracking-id/detail.vue'),
            children: [
                {
                    path: '/inbound/trackingiddetailview',
                    name: 'inbound.trackingIdDetailView',
                    meta: {
                        menuHide: true,
                        title: 'Tracking ID Detail',
                        icon: '',
                        navList: [
                            {
                                routeName: 'inbound',
                                title: 'Inbound',
                            },
                            {
                                routeName: 'PC.Inbound.Order.TrackingID.View',
                                title: 'Tracking ID',
                            },
                        ],
                    },
                    component: () => import('../../views/inbound/order/tracking-id/detail-view.vue'),
                },
            ],
        },
        {
            path: 'massputaway',
            name: 'inbound.massPutaway',
            meta: {
                title: 'Mass Putaway',
                icon: '',
            },
            component: () => import('../../views/inbound/mass-putaway/mass-putaway.vue'),
        },
        {
            path: 'massputaway/reportexception',
            name: 'inbound.massputawayReportException',
            meta: {
                title: 'Report Exception',
                icon: '',
                menuHide: true,
                navList: [
                    {
                        routeName: 'Inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'inbound.massPutaway',
                        title: 'Mass Putaway',
                    },
                ],
            },
            component: () => import('../../views/inbound/mass-putaway/report-exception.vue'),
        },
        {
            path: 'labourplanning',
            name: 'inbound.laborPlanning',
            meta: {
                title: 'Inbound Staff Planning',
                icon: '',
            },
            component: () => import('../../views/inbound/labour-planning/labour-planning.vue'),
        },
        {
            path: '/inbound/reprocessorder',
            name: 'PC.Inbound.Order.ReprocessOrder.View',
            meta: {
                menuHide: true,
                title: 'Reprocess Order',
                icon: '',
                filterSave: true,
            },
            component: () => import('../../views/inbound/order/index.vue'),
        },
        {
            path: '/inbound/reprocessorder/:id',
            name: 'inbound.order.reprocess.detail',
            meta: {
                menuHide: true,
                title: 'Reprocess Order Detail',
                icon: '',
                navList: [
                    {
                        routeName: 'inbound',
                        title: 'Inbound',
                    },
                    {
                        routeName: 'PC.Inbound.Order.ReprocessOrder.View',
                        title: 'Reprocess Order',
                    },
                ],
            },
            component: () => import('@/views/inbound/order/reprocess/detail.vue'),
        },
        {
            path: 'reprocess/:task_id?',
            name: 'inbound.reprocess',
            meta: {
                title: 'Reprocess',
                icon: '',
            },
            component: () => import('../../views/inbound/reprocess/reprocess.vue'),
        },
        {
            path: 'inbound/crossDockSorting',
            name: 'inbound.crossdocksorting',
            meta: {
                title: 'Cross Dock Sorting',
            },
            component: reactContainerFactory({
                name: 'inbound.crossdocksorting',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/cross-dock-sorting')),
            },
        },
        // {
        //   path: 'newskuattributescollection',
        //   name: 'inbound.newskuattributescollection',
        //   meta: {
        //     title: 'SKU Attributes Collection',
        //     icon: '',
        //   },
        //   component: () =>
        //     import('../../views/inbound/new-sku-attributes-collection/index.vue'),
        // },
    ],
};
export default inbound;
