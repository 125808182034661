import router from './router';
import store from './store';
import { hasMenuPermission } from '@/utils/checkPermission';
import { getNextTabPageNameWithPermission, isTabPage, hasTabPagePermission, } from '@/utils/tab-permission';
import { permissionLog } from '@/utils/checkPermission';
import { runLoader } from '../loader';
/*兼容新老rms，后期稳定可以删掉*/
import { getCookie } from '@/utils/cookie';
import { errorReport } from '@/utils/tools';
const isRmsNewVersion = () => {
    let flag = false;
    try {
        let rmsFlagObj = {};
        let whs = '';
        //获取仓库
        whs = getCookie('setting_whs_v2') || '';
        if (!whs) {
            return flag;
        }
        //获取localstorage rms-flag
        const rmsFlagStr = localStorage.getItem('rms-flag');
        if (!rmsFlagStr) {
            return flag;
        }
        rmsFlagObj = JSON.parse(rmsFlagStr);
        if (typeof rmsFlagObj[whs] != 'undefined') {
            flag = rmsFlagObj[whs];
        }
        return flag;
    }
    catch (e) {
        errorReport(e);
        return flag;
    }
};
const rmsDeal = () => {
    try {
        const whs = getCookie('setting_whs_v2') || '';
        if (!whs) {
            return;
        }
        const flag = isRmsNewVersion();
        const newFlag = store.state.warehouseDetail.warehouse.is_rms || false;
        if (flag !== newFlag) {
            const rmsFlagStr = localStorage.getItem('rms-flag');
            let rmsFlagObj = {};
            if (rmsFlagStr) {
                rmsFlagObj = JSON.parse(rmsFlagStr);
            }
            rmsFlagObj[whs] = newFlag;
            localStorage.setItem('rms-flag', JSON.stringify(rmsFlagObj));
            setTimeout(() => {
                location.reload();
            }, 200);
        }
    }
    catch (e) {
        errorReport(e);
    }
};
/*兼容新老rms，后期稳定可以删掉*/
// 不重定向白名单
const whiteList = [
    '/login',
    '/google/login',
    '/tob/login',
    '/tob/callback',
    '/forget',
    '/reset',
    '/resetSuccess',
    '/login.html',
    '/login_callback.html',
    '/403',
    '/404',
];
router.beforeEach(async (to, from, next) => {
    if (whiteList.includes(to.path)) {
        next();
    }
    else {
        // 刷新页面（包括登陆）时，拉取用户信息等
        if (!store.state.user.userInfo.email) {
            try {
                await store.dispatch('getUserInfo');
                const dispatchArr = [
                    'loadSystemEnums',
                    'loadPermissionMenu',
                    'loadUserPermission',
                    'loadTabPageNames',
                    'loadWarehouseSwitchTime',
                    'loadWarehouseDetail',
                    'loadTabMenu',
                    'loadStaffSwitch',
                    'loadShowStoreyTab',
                    // 'getOldSystemUrlMap',
                ];
                const taskArr = dispatchArr.map((v) => {
                    return store.dispatch(v);
                });
                await Promise.all(taskArr);
                /*兼容新老rms，后期稳定可以删掉*/
                rmsDeal();
                /*兼容新老rms，后期稳定可以删掉*/
                // 若获取声音失败，不阻塞登录流程
                try {
                    await store.dispatch('getNotificationSoundConfigList');
                }
                catch (e) {
                    console.log('error', e);
                }
                // 若获取弱网监测参数失败，不阻塞流程
                try {
                    await store.dispatch('loadDisplaySettings');
                }
                catch (e) {
                    console.log('error', e);
                }
                // 获取是否打开全仓盘点
                await store.dispatch('loadOpenFullStockCountSign');
                if (hasMenuPermission(to)) {
                    if (isTabPage(to.name) && !hasTabPagePermission(to.name)) {
                        next({ name: getNextTabPageNameWithPermission(to.name) });
                    }
                    else {
                        runLoader();
                        next();
                    }
                }
                else {
                    permissionLog(store.state.permission.permissionMenu, store.state.permission.addRouters, to.name);
                    next('/403');
                }
            }
            catch (e) {
                console.log(e);
                next('/tob/login');
            }
            finally {
                localStorage.setItem('useremail', store.state.user.userInfo.email);
                localStorage.setItem('stationId', store.state.warehouseDetail.warehouse.whs_no);
            }
        }
        else {
            if (hasMenuPermission(to)) {
                if (isTabPage(to.name) && !hasTabPagePermission(to.name)) {
                    next({ name: getNextTabPageNameWithPermission(to.name) });
                }
                else {
                    next();
                }
            }
            else {
                permissionLog(store.state.permission.permissionMenu, store.state.permission.addRouters, to.name);
                next('/403');
            }
        }
    }
});
