import Vue from 'vue';
import { locales, tspLanguageMap } from './locales';
import mixin from './mixin';
import { config } from './config';
import { update, addComponentInstance } from './components';
import { getCookie } from '@/utils/cookie';
import { safeGet } from '@/utils/safeGet';
import isPlainObject from '@/utils/vue-types/is-plain-object';
import isEmpty from '@/utils/isEmpty';
import { getSSCLanguage } from './ssc-lang';
import TSPSDK from 'tsp-sdk';
import TranslateReport from '@/utils/translateReport';
import router from '@/router';
let langs = locales;
export const EventBus = new Vue();
// tsp-sdk init
const translator = new TSPSDK({
    name: 'wms-ui', //  可选标识名
});
async function getTranslateJSon(lang) {
    try {
        const { translation } = await translator.create({
            env: process.env.VUE_APP_RUN_ENV,
            locale: lang,
            translation: {
                resources: [618],
            },
        });
        // 将组件库翻译包合入
        const sscLang = await getSSCLanguage(lang);
        const mergeLang = Object.assign({}, translation, {
            'ssc-ui-vue': sscLang,
        });
        langs[lang] = {
            ...mergeLang,
        };
        EventBus.$emit('langChange');
        update();
    }
    catch (e) {
        console.log('error', e);
    }
}
/**
 * 反复调用时，会追加翻译数据
 * @param {{
 *   [key: string]: {}
 * }} data
 */
function mergeLangs(data) {
    // todo: preset langs with locales
    if (data && typeof data === 'object') {
        const merged = {};
        for (const [key, lang] of Object.entries(data)) {
            if (langs[key]) {
                merged[key] = {
                    ...langs[key],
                    ...lang,
                };
            }
            else {
                merged[key] = {
                    ...lang,
                };
            }
        }
        langs = merged;
    }
}
const getCurrentLocale = () => {
    return getCookie('setting_lang');
};
const format = (str = '', params = []) => {
    let index = 0;
    return str.replace(/%s/g, () => {
        const res = index >= params.length ? '%s' : String(params[index]);
        index++;
        return String(res);
    });
};
const i18n = (key, params = [], is_toast_error = false) => {
    const currentLocale = tspLanguageMap.get(getCurrentLocale()) || getCurrentLocale();
    // missing data
    if (!langs[currentLocale]) {
        return key;
    }
    const translatedText = langs[currentLocale][key];
    // 没有翻译文案也是返回 key
    if (!translatedText) {
        if (currentLocale === 'zh-Hans') {
            if (is_toast_error) {
                TranslateReport.toastUnTranslateReport(key);
            }
            else {
                TranslateReport.addFailTranslateKey(router.currentRoute.name ?? '', key);
            }
        }
        return format(key, params);
    }
    if (currentLocale === 'zh-Hans') {
        TranslateReport.addSuccessTranslateCount(router.currentRoute.name ?? '');
    }
    return format(translatedText, params);
};
function gtFormatHelper(template, data) {
    return template.replace(/\{(\w+)\}/g, (match, key) => {
        return String(data[key]);
    });
}
const gt = (key, options = {}, params = {}) => {
    const currentLocale = tspLanguageMap.get(getCurrentLocale()) || getCurrentLocale();
    // missing data
    if (!langs[currentLocale]) {
        return key;
    }
    const translatedText = langs[currentLocale][key];
    // 没有翻译文案也是返回 key
    if (!translatedText) {
        if (currentLocale === 'zh-Hans') {
            TranslateReport.addFailTranslateKey(router.currentRoute.name ?? '', key);
        }
        return gtFormatHelper(key, params);
    }
    if (currentLocale === 'zh-Hans') {
        TranslateReport.addSuccessTranslateCount(router.currentRoute.name ?? '');
    }
    return gtFormatHelper(translatedText, params);
};
export const sscComponentI18n = function (path, options) {
    // 1. 读取缓存中的语言并作 map
    const currentLocale = tspLanguageMap.get(getCurrentLocale()) || getCurrentLocale();
    if (!langs[currentLocale]) {
        return;
    }
    const sscLang = langs[currentLocale]['ssc-ui-vue'] || {};
    let res = safeGet(sscLang, path);
    if (res && options && isPlainObject(options)) {
        const values = res.match(/{\w+}/g);
        values.forEach((value) => {
            const key = value.slice(1, -1);
            if (!isEmpty(options[key]))
                res = res.replace(value, options[key]);
        });
    }
    return res;
};
function addI18nData(data) {
    mergeLangs(data);
    update();
}
const setLocale = (l) => {
    localStorage.setItem(config.storageKey, l);
    const [title, suffix] = document.title.split(' - ');
    document.title = suffix || title;
};
const install = ((Vue) => {
    if (install.installed) {
        console.warn('already installed.');
        return;
    }
    install.installed = true;
    Vue.mixin(mixin);
    Vue.prototype.$translate = Vue.prototype.$i18n = i18n;
});
if (typeof window !== 'undefined' && window.Vue) {
    const Vue = window.Vue;
    install(Vue);
}
const translatedIsNotEN = (value) => {
    if (getCurrentLocale !== 'en') {
        if (!value.match(/^[0-9A-Za-z\s.:,)(）（'";_{}!%<>/-[\]-]+$/g)) {
            return true;
        }
    }
    return false;
};
export { getCurrentLocale, setLocale, i18n, gt, addI18nData, addComponentInstance, getTranslateJSon, translatedIsNotEN, };
export default { install };
